<template>
  <div class="h5-container h5-queue-apply-succeed-container">
    <!-- <h4 class="title">叫号面试签到</h4> -->
    <div class="queue-apply-succeed-body">
      <div class="head-title">
        <i class="h5pp h5pp-icon-right" />
        <h3 class="title">{{ resName }}，您好！签到成功！</h3>
        <p class="title-desc">扫描二维码，关注公众号获取面试通知</p>
      </div>
      <div class="queue-interview-info">
        <dl class="queue-head">
          <dt><i class="h5pp h5pp-icon-wait" />等待面试</dt>
          <dd>已为您排号，请注意查收面试通知！</dd>
        </dl>
        <div class="queue-body">
          <div class="queue-content">
            <p class="queue-num-label">排号号码</p>
            <h2 class="queue-num">{{ queueNum }}</h2>
            <p class="hr-name">HR负责人：{{ accountName }}</p>
          </div>
          <div class="wechat-box">
            <div class="img-wechat">
              <img src="@/assets/images/queue/img_wechat.jpg" class="img-wechat-code" />
            </div>
            <p class="wechat-tips">长按识别二维码，<br>关注公众号，获取面试通知</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import './index.scss'

  export default {
    computed: {
      resName() {
        return this.$route.query.resName || '';
      },
      queueNum() {
        return this.$route.query.queueNum || '';
      },
      accountName() {
        return this.$route.query.accountName || '';
      }
    },
    created() {
      document.title = {1: '叫号面试签到', 2: '现场申请'}[this.$route.query.type];
    }
  }
</script>
