<template>
  <div class="h5-container">
    <div class="job-list-wrap">
      <sub-h5-apply-name :name="name" />
      <van-sticky>
        <div class="query-condition">
          <van-search v-model.trim="searchParams.keyWord" placeholder="请输入职位关键字" class="input-search" left-icon="" input-align="center" @search="onReQuery()"/>
        </div>
      </van-sticky>
      <van-pull-refresh v-model="refreshing" @refresh="onReQuery">
        <van-list class="job-list" v-model="isLoading" :finished="finished" finished-text="没有更多了" @load="queryDatas()">
          <van-cell v-for="(item, key) in jobList" :key="key">
            <a @click="onApply(item.id)" href="javascript:;">
              <dl class="item-job">
                <dt v-if="item.jobName" class="clearfix">
                  <h3 class="title el-sl">{{ item.jobName }}</h3>
                  <p v-if="item.minSalary && item.maxSalary" class="salary">
                    <template v-if="item.salaryType === 1">{{ item.minSalary }}K-{{ item.maxSalary }}K・{{ item.monthSalaryType }}<span v-if="item.monthSalaryType>12">薪</span></template>
                    <template v-else>{{ item.minSalary }}W-{{ item.maxSalary }}W</template>
                  </p>
                </dt>
                <dd>
                  <ul v-if="item.department || item.categoryId || item.jobClassifyName || item.jobRankNames" class="job-divide-info clearfix">
                    <li v-if="item.department" class="org-name el-sl">{{ item.department }}</li>
                    <li v-if="item.categoryId" class="functions el-sl">{{ item.categoryId | FUNCTION }}</li>
                    <li v-if="item.jobClassifyName" class="job-classify el-sl">{{ item.jobClassifyName }}</li>
                    <li v-if="item.jobRankNames && item.jobRankNames.length" class="rank el-sl">{{ item.jobRankNames.join(',') }}</li>
                  </ul>
                  <ul v-if="(item.eduRequire || item.city)" class="job-divide-info address-box clearfix">
                    <li v-if="item.eduRequire">{{ item.eduRequire | JOBDEGREE }}</li>
                    <li v-if="item.city" class="el-sl">{{ item | FULLADDESS }}</li>
                  </ul>
                  <ul v-if="(item.demand || item.modifyTime)" class="job-between-info">
                    <li v-if="item.demand">招聘人数：{{ item.demand }}人</li>
                    <li v-if="item.modifyTime" class="create-date">发布时间：{{ item.modifyTime | DATE('YYYY-MM-DD') }}</li>
                  </ul>
                </dd>
              </dl>
            </a>
          </van-cell>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
  import './index.scss'
  import wxapi from '@/utils/wxapi'
  import { mapGetters } from 'vuex'
  import { Sticky, Search, PullRefresh, List, Cell, Popup } from 'vant'
  import subH5ApplyName from '@/views/submodule/h5/queue/applyName'

  export default {
    components: {
      [Sticky.name]: Sticky,
      [Search.name]: Search,
      [PullRefresh.name]: PullRefresh,
      [List.name]: List,
      [Cell.name]: Cell,
      [Popup.name]: Popup,
      subH5ApplyName
    },
    data() {
      return {
        isLoading: false,
        finished: false,
        refreshing: false,
        searchParams: {
          companyId: '',
          projectId: '',
          keyWord: '',
          current: 0,
          pageSize: 20
        },
        jobList: []
      }
    },
    computed: {
      openId() {
        return this.$route.query.openId || '';
      },
      name() {
        return this.$route.query.name || '';
      },
      mobile() {
        return this.$route.query.mobile || '';
      }
    },
    created() {
      this.searchParams.companyId = this.$route.query.companyId || '';
      this.searchParams.projectId = this.$route.query.projectId || '';
      this.searchParams.keyWord = this.$route.query.keyWord || '';
      this.queryDatas();
    },
    methods: {
      queryDatas() {
        this.searchParams.current++;
        this.isLoading = true;
        let queryParams = this.$utils.deleteKeys(Object.assign({}, this.searchParams));
        if(+this.$route.query.type === 1) {
          queryParams.mobile = this.mobile;
        }
        this.$http.get('/api/school/candidate/job/list.json', {params: {
          ...queryParams
        }}).then(data => {
          if(data && data.list && data.list.length) {
            this.jobList = [...this.jobList, ...data.list];
          }
          if(this.jobList.length >= data.totalRecords) {
            this.finished = true;
          }
        }).catch(err => {
          this.finished = true;
        }).finally(() => {
          // 加载状态结束
          this.isLoading = false;
          if(this.refreshing) {
            this.refreshing = false;
          }
        });
      },
      onReQuery() {
        // 清空列表数据
        this.finished = false;
        this.jobList = [];
        this.searchParams.current = 0;
        // 重新加载数据
        this.queryDatas();
      },
      onApply(jobId) {
        this.$router.push({
          name: 'queueAddResume',
          query: { 
            companyId: this.searchParams.companyId,
            projectId:  this.searchParams.projectId,
            openId: this.openId,
            name: this.name,
            mobile: this.mobile,
            jobId
          }
        });
      }
    }
  }
</script>