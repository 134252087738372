<template>
  <div class="h5-container h5-queue-apply-container">
    <!-- <h4 class="title">叫号面试签到</h4> -->
    <div class="queue-apply-body">
      <h3 class="title">您好，请进行签到面试</h3>
      <p class="title-desc">填写简历中的手机号码进行签到</p>
      <van-form class="apply-form" ref="applyForm" validate-trigger="onSubmit" scroll-to-error @submit="onSave">
        <van-field v-if="type === '2'" v-model.trim="applyForm.name" :rules="[{ required: true, message: '请输入姓名', trigger: 'onBlur'}]" :maxlength="100" clearable placeholder="请输入姓名" />
        <van-field v-model.trim="applyForm.mobile" :rules="[{ required: true, message: '请输入手机号', trigger: 'onBlur'}]" :maxlength="11" clearable placeholder="请输入手机号" />
        <van-button v-if="type === '1'" type="primary" :loading="isBtnLoading" native-type="submit">签到</van-button>
        <van-button v-else type="primary" :loading="isBtnLoading" native-type="submit">下一步</van-button>
      </van-form>
    </div>
    <popup-sign-fail v-if="signFailObj.isShow" :dialogData="signFailObj" />
  </div>
</template>

<script>
  import './index.scss'
  import { Form, Field, Button } from 'vant'
  import popupSignFail from '@/components/h5/dialog/signFail'

  export default {
    components: {
      [Form.name]: Form,
      [Field.name]: Field,
      [Button.name]: Button,
      popupSignFail
    },
    data() {
      return {
        isBtnLoading: false,
        type: this.$route.query.type || '1',
        companyId: this.$route.query.companyId || '39080', // TODO
        openId: '',
        applyForm: {
          name: '',
          mobile: ''
        },
        signFailObj: { isShow: false }
      }
    },
    created() {
      document.title = {1: '叫号面试签到', 2: '现场申请'}[this.type];
      this.queryOpenId();
    },
    methods: {
      queryOpenId() {
        const code = this.$utils.getUrlParams('code'); // 截取路径中的code，如果没有就去微信授权，如果已经获取到了就直接传code给后台获取openId
        if(!code) {
          console.log('未获取到微信唯一code，请重新扫码签到');
          return;
        }
        // 获取链接里的参数
        this.type = this.$utils.getUrlParams('type');
        this.companyId = this.$utils.getUrlParams('companyId');
        this.$http.get('/api/school/candidate/get/openid.json', {params: { code }}).then(data => {
          this.openId = data;
        });
      },
      onSave() {
        this.$refs.applyForm.validate().then(() => {
          // 现场签到去选择职位然后投递
          if(this.type === '2') {
            this.$router.push({
              name: 'queueProjects',
              query: { 
                type: 2,
                companyId: this.companyId,
                openId: this.openId,
                name: this.applyForm.name,
                mobile: this.applyForm.mobile
              }
            });
            return;
          }
          this.isBtnLoading = true;
          this.$http.post('/api/school/candidate/sign.json', {
            companyId: this.companyId,
            mobile: this.applyForm.mobile,
            openId: this.openId
          }, {global: true}).then(({ data }) => {
            if(['school_interview_003', 'school_interview_004'].includes(data.status)) {
              this.signFailObj.isShow = true;
              return;
            }
            // 签到成功（单个项目）
            if(data.data && data.data.signResult) {
              this.$router.push({
                name: 'queueApplySucceed',
                query: { 
                  type: 1,
                  resName: data.data.resName,
                  queueNum: data.data.queueNum,
                  accountName: data.data.accountName
                }
              });
            } else { //有多个项目待选择
              this.$router.push({
                name: 'queueProjects',
                query: { 
                  type: 1,
                  companyId: this.companyId,
                  openId: this.openId,
                  mobile: this.applyForm.mobile
                }
              });
            }
            this.isBtnLoading = false;
          }).catch(err => {
            console.error(err);
          }).finally(() => {
            this.isBtnLoading = false;
          });
        });
      }
    }
  }
</script>
