<template>
  <div class="h5-container" @scroll="(e) => onScroll(e)">
    <sub-h5-apply-name :name="$route.query.name || ''" />
    <div class="resume-add-wrap">
      <van-form class="res-form" ref="resForm" validate-trigger="onSubmit" scroll-to-error @submit="onSave">
        <div class="form-filed-box">
          <h3>附件简历</h3>
          <div class="form-item-upload">
            <van-field label="附件简历" name="resumeFileId" class="not-cell">
              <template #input>
                <van-uploader
                  v-if="!resForm.resumeFileId || !resForm.resumeFileId.length"
                  v-model="resForm.resumeFileId"
                  :max-count="1"
                  :max-size="10 * 1024 * 1024"
                  @oversize="onOversize"
                  :accept="acceptRes"
                  :after-read="afterReadRes">
                  <div class="upload-btn"><i class="h5pp h5pp-icon-append"></i></div>
                </van-uploader>
                <ul v-else class="file-list">
                  <li v-for="(item, key) in resForm.resumeFileId" :key="key">
                    <p class="file-name el-sl">{{ item.originName }}</p>
                    <i class="h5pp h5pp-icon-delete" @click.stop="onItemsFile(key, 'resumeFileId')"></i>
                  </li>
                </ul>
              </template>
            </van-field>
            <p class="upload-tips">支持pdf、doc、xls、ppt、docx、pptx、wps、html、jpg、jpeg、png、rtf、txt等简历格式</p>
          </div>
          <template v-for="(config, configIndex) in configList">
            <sub-base v-if="isShowModule(config, 1)" :key="configIndex" :moduleId="config.moduleId" :filedsConfig="config.fields" ref="resBase" :isDisabledPhone="true" :resForm="resForm.resumeInfo" />
            <!-- 教育经历 -->
            <div v-else-if="isShowModule(config, 4)" :key="configIndex" class="form-item">
              <h3 :class="{'require-tip': isRequired(config.fields).length}">教育经历</h3>
              <van-field name="eduExperience" :class="{'no-val-item': !resForm.eduExperience.length}" :rules="[{ required: !resForm.eduExperience.length && isRequired(config.fields).length, message: '教育经历必填'}]">
                <template v-if="resForm.eduExperience.length" #input>
                  <div v-for="(item, key) in resForm.eduExperience" :key="'edu_'+key" class="exp-item" @click.stop="addExp('EducationExp', config.moduleId, item, key+1)">
                    <sub-list-item :fields="config.fields" :dataObj="item" :moduleCode="4" />
                    <a href="javascript:;" class="remove-btn" @click.stop="removeItems('eduExperience', key)"><i class="h5pp h5pp-icon-subtract"></i>删除</a>
                  </div>
                </template>
              </van-field>
              <van-button native-type="button" icon="add-o" plain class="btn-add-item" @click="addExp('EducationExp', config.moduleId)">添加</van-button>
            </div> 
          </template>
        </div>
        <van-tabbar>
          <van-button :loading="isBtnLoading" native-type="submit">确认提交</van-button>
        </van-tabbar>
      </van-form>
    </div>
  </div>
</template>

<script>
  import './index.scss'
  import { Form, Field, Uploader, Tabbar, Button, Toast } from 'vant'
  import { local } from '@/utils'
  import subH5ApplyName from '@/views/submodule/h5/queue/applyName'
  import subBase from './submodule/base'
  import subListItem from './submodule/listItem'

  export default {
    components: {
      [Form.name]: Form,
      [Field.name]: Field,
      [Uploader.name]: Uploader,
      [Tabbar.name]: Tabbar,
      [Button.name]: Button,
      subH5ApplyName,
      subBase,
      subListItem
    },
    data() {
      return {
        isBtnLoading: false,
        acceptRes: 'image/png,image/jpg,image/jpeg,.pdf,.doc,.xls,.ppt,.docx,.pptx,.wps,.html,.rtf,.txt',
        configList: [],
        resForm: {
          resumeFileId: [], //简历附件
          resumeInfo: {
            name: this.$route.query.name || '',
            area: 1,
            phone: this.$route.query.mobile || '',
            email: this.$route.query.email || ''
          },
          eduExperience: []
        },
        currentIndex: 0,
        scrollY: ''
      }
    },
    computed: {
      companyId() {
        return this.$route.query.companyId || '';
      },
      openId() {
        return this.$route.query.openId || '';
      },
      projectId() {
        return this.$route.query.projectId || '';
      },
      jobId() {
        return this.$route.query.jobId || '';
      },
      isShowModule() {
        return (module, moduleCode) => {
          return module.isOpen && module.moduleDto.moduleCode === moduleCode;
        }
      },
    },
    created() {
      if(this.companyId) {
        this.queryConfigField();
      }
    },
    activated() {
      if(this.$route.query.field) {
        this.resForm = local.getObject('resForm');
        this.scrollY = local.get('scrollY') || 0;
        if(this.$route.query.field === 'customExp') {
          if(+this.$route.query.currentIndex && this.$route.query[this.$route.query.field]) {
            if(this.resForm.customModules[this.$route.query.moduleId]) {
              this.resForm.customModules[this.$route.query.moduleId][+this.$route.query.currentIndex - 1] = JSON.parse(this.$route.query[this.$route.query.field]);
            } else {
              this.resForm.customModules[this.$route.query.moduleId] = [JSON.parse(this.$route.query[this.$route.query.field])];
            }
          } else if(this.$route.query[this.$route.query.field]) {
            if(this.resForm.customModules[this.$route.query.moduleId]) {
              this.resForm.customModules[this.$route.query.moduleId].push(JSON.parse(this.$route.query[this.$route.query.field]));
            } else {
              this.resForm.customModules[this.$route.query.moduleId] = [JSON.parse(this.$route.query[this.$route.query.field])];
            }
          }
        } else if(+this.$route.query.currentIndex && this.$route.query[this.$route.query.field]) {
          this.resForm[this.$route.query.field][+this.$route.query.currentIndex - 1] = JSON.parse(this.$route.query[this.$route.query.field]);
          this.$forceUpdate();
        } else if(this.$route.query[this.$route.query.field]){
          this.resForm[this.$route.query.field].push(JSON.parse(this.$route.query[this.$route.query.field]));
        }
      } else if(!this.$route.query.field && local.getObject('resForm') && Object.keys(local.getObject('resForm')).length){
        local.remove('resForm');
        local.remove('scrollY');
      }
      this.$nextTick(() => {
        document.querySelector('.h5-container').scrollTop = +this.scrollY;
      });
    },
    methods: {
      // 处理页面滚动
      onScroll(e) {
        this.scrollY = e.target.scrollTop;
      },
      queryConfigField() {
        this.$http.get('/api/school/candidate/delivery/config.json', {params: {
          companyId: this.companyId
        }}).then(data => {
           if(data && data.length) {
            this.configList = data;
            // 处理展示内容，不按后端配置走
            this.configList.map(item => {
              if(item.moduleDto) {
                if([1, 4].includes(item.moduleDto.moduleCode)) {
                  item.isOpen = 1; //展示大模块
                }
                if(item.moduleDto.moduleCode === 1 && item.fields && item.fields.length) {
                  item.fields.map(obj => {
                    if(obj.fieldDto && ['name', 'phone', 'email'].includes(obj.fieldDto.fieldCode)) {
                      obj.isCheck = 1; //展示子模块
                    }
                  });
                }
              }
            });
          }
        });
      },
      isRequired(list) {
        return list.filter(item => item.isCheck && item.isRequired);
      },
      afterReadRes(data) {
        const uploadParams = new FormData();
        uploadParams.append('companyId', this.companyId);
        uploadParams.append('source', 21);
        uploadParams.append('file', data.file);
        this.$http.post('/api/file/upload.json', uploadParams, {
          headers: { 'Content-Type': 'multipart/form-data' }
        }).then(data => {
          if(data) {
            this.resForm.resumeFileId = [{
              url: data.filePath,
              originName: data.fileName,
              id: data.id
            }];
            this.$refs.resForm.resetValidation('resumeFileId');
            Toast.success({
              message: '上传成功',
              duration: 3000
            });
          }
        })
      },
      onOversize() {
        Toast('文件大小不能超过 10MB');
      },
      onItemsFile(key, fileType) {
        this.resForm[fileType].splice(key, 1);
      },
      addExp(field, moduleId, obj, index) {
        let resumeForm = null;
        if(obj) {
          resumeForm = JSON.stringify(obj);
        }
        this.currentIndex = index || 0;
        let newResForm = {...this.resForm};
        local.setObject('resForm', newResForm);
        local.set('scrollY', this.scrollY);
        this.$router.push({
          name: field,
          query: {
            ...this.$route.query,
            currentIndex: this.currentIndex,
            resumeForm,
            moduleId,
            pathName: 'queueAddResume'
          }
        })
      },
      removeItems(field, key, moduleId) {
        this.resForm[field].splice(key, 1);
        local.setObject('resForm', this.resForm);
      },
      getCustomData(data, moduleId) {
        let fields = [];
        if(data.customFields && Object.keys(data.customFields).length) {
          Object.keys(data.customFields).forEach(key => {
            fields.push(data.customFields[key])
          });
        }
        delete data.customFields;
        if(fields.length) {
          return {
            moduleId,
            fields
          }
        } else {
          return null;
        }
      },
      queryModuleIdByCode(code) {
        const module = this.configList.find(item => item.moduleDto.moduleCode === code);
        return module && module.moduleId ? module.moduleId : '';
      },
      formatItemDate(data, moduleId) {
        if(!data || !data.length) return [];
        let standardExpArr = [];
        data.forEach(obj => {
          let item = Object.assign({}, obj);
          if(moduleId) {
            const customResult = this.getCustomData(this.$utils.deepClone(obj), moduleId);
            if(customResult) {
              item.customResult = customResult;
            }
          }
          delete item.dates;
          delete item.customFields;
          delete item.fileList;
          item = this.$utils.deleteKeys(item);
          if(Object.keys(item).length) {
            standardExpArr.push(item);
          }
        });
        return standardExpArr.length ? standardExpArr: [];
      },
      onSave() {
        this.$refs.resForm.validate().then(async (valid) => {
          const newForm = this.$utils.deepClone(this.resForm);
          const dataMap = {
            'resumeInfo': {
              code: 1,
              name: 'resumeInfo'
            },
            'eduExperience': {
              code: 4,
              name: 'educationExperience'
            }
          };
          Object.keys(dataMap).forEach(key => {
            if(key === 'resumeInfo') {
              const customResult = this.getCustomData(newForm[key], this.queryModuleIdByCode(dataMap[key].code));
              newForm[dataMap[key].name] = this.$utils.formatDataArr(newForm[key]);
              if(customResult) {
                newForm[dataMap[key].name].customResult = customResult;
              }
              newForm[dataMap[key].name] = JSON.stringify(newForm[dataMap[key].name]);
            } else {
              newForm[dataMap[key].name] = this.formatItemDate(newForm[key], this.queryModuleIdByCode(dataMap[key].code));
              newForm[dataMap[key].name] = JSON.stringify(newForm[dataMap[key].name]);
            }
            if(key !== dataMap[key].name) {
              delete newForm[key];
            }
          });
          local.setObject('resForm', newForm);
          let savedForm = this.$utils.deleteKeys(Object.assign({}, newForm, {
            companyId: this.companyId,
            openId: this.openId,
            projectId: this.projectId,
            jobId: this.jobId,
            resumeFileId: (newForm.resumeFileId && newForm.resumeFileId.length) ? newForm.resumeFileId[0].id : '',
          }));   
          this.isBtnLoading = true;
          this.$http.post('/api/school/candidate/scene/sign.json', savedForm).then(data => {
            // 签到失败
            if(['school_interview_003', 'school_interview_004'].includes(data.status)) {
              this.signFailObj.isShow = true;
              return;
            }
            // 签到成功
            this.$router.push({
              name: 'queueApplySucceed',
              query: { 
                type: 2,
                resName: data.resName,
                queueNum: data.queueNum,
                accountName: data.accountName
              }
            });
            this.isBtnLoading = false;
          }).catch(err => {
            console.log(err);
          }).finally(() => {
            this.isBtnLoading = false;
          });
        });
      }
    },
    // watch: {
    //   pathName: {
    //     handler(val) {
    //       if(val){
    //         this.init();
    //       }
    //     },
    //     deep: true
    //   }
    // }
  }
</script>