<template>
  <div class="h5-container h5-queue-projects-container">
    <!-- <h4 class="title">叫号面试签到</h4> -->
    <div class="queue-projects-body">
      <h3 class="title">可能有多场面试正在进行中</h3>
      <p class="title-desc">请选择您要参加的专场</p>
      <ul class="projects-box">
        <li v-for="(item, key) in projectList" :key="key" @click="onApply(item.id)">
          <dl class="project-item">
            <dt><img src="@/assets/images/queue/icon_project_title.png" class="img-project-title" /><strong>{{ item.name }}</strong></dt>
            <dd>面试时间：{{ item.interviewDate | DATE }}</dd>
          </dl>
          <i class="h5pp h5pp-icon-arrows-right" />
        </li>
      </ul>
    </div>
    <popup-sign-fail v-if="signFailObj.isShow" :dialogData="signFailObj" />
  </div>
</template>

<script>
  import './index.scss'
  import popupSignFail from '@/components/h5/dialog/signFail'

  export default {
    components: {
      popupSignFail
    },
    data() {
      return {
        isBtnLoading: false,
        projectList: [],
        signFailObj: { isShow: false }
      }
    },
    computed: {
      type() {
        return this.$route.query.type || '';
      },
      companyId() {
        return this.$route.query.companyId || '';
      },
      openId() {
        return this.$route.query.openId || '';
      },
      name() {
        return this.$route.query.name || '';
      },
      mobile() {
        return this.$route.query.mobile || '';
      }
    },
    created() {
      this.queryDatas();
    },
    methods: {
      queryDatas() {
        let params = {
          companyId: this.companyId
        };
        if(+this.type === 1) {
          Object.assign(params, {
            mobile: this.mobile
          });
        }
        this.$http.get('/api/school/candidate/project/list.json', {params}).then(data => {
          if(data) {
            this.projectList = data;
            // 现场签到：只有一个项目，跳转到职位列表
            if(+this.type === 2 && this.projectList && this.projectList.length === 1) {
              this.onApply(this.projectList[0].id);
            }
          }
        });
      },
      onApply(projectId) {
        // 现场签到
        if(+this.type === 2) {
          this.$router.push({
            name: 'queueJobs',
            query: { 
              type: this.type,
              companyId: this.companyId,
              openId: this.openId,
              name: this.name,
              mobile: this.mobile,
              projectId
            }
          });
          return;
        }
        this.isBtnLoading = true;
        this.$http.post('/api/school/candidate/sign.json', {
          companyId: this.companyId,
          mobile: this.mobile,
          openId: this.openId,
          projectId
        }, {global: true}).then(({ data }) => {
          // 签到失败
          if(['school_interview_003', 'school_interview_004'].includes(data.status)) {
            this.signFailObj.isShow = true;
            return;
          }
          // 签到成功（单个项目）
          if(data.data && data.data.signResult) {
            this.$router.push({
              name: 'queueApplySucceed',
              query: { 
                type: 1,
                resName: data.data.resName,
                queueNum: data.data.queueNum,
                accountName: data.data.accountName
              }
            });
          }
          this.isBtnLoading = false;
        }).catch(err => {
          console.error(err);
        }).finally(() => {
          this.isBtnLoading = false;
        });
      }
    }
  }
</script>
