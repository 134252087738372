<template>
  <van-dialog 
    v-model="dialogData.isShow" 
    :title="dialogData.title"
    confirmButtonText="确定" 
    confirmButtonColor="#fff"
    class="dialog-sign-fail">
    <i class="h5pp h5pp-icon-warn" />
    <h4 class="title">签到失败</h4>
    <div class="desc">
      <p class="desc-txt">此手机号没有预约参加此次面试！</p>
      <p class="desc-txt">请确认手机号是否为简历中的手机号码！</p>
    </div>
  </van-dialog>
</template>

<script>
  import './index.scss'
  import { Dialog, Button } from 'vant'

  export default {
    components: {
      [Dialog.Component.name]: Dialog.Component,
      [Button.name]: Button
    },
    props: {
      dialogData: Object
    }
  }
</script>