<template>
  <sub-h5-queue-apply v-if="$utils.isPhone()" />
  <div v-else>PC端功能暂不支持</div>
</template>

<script>
  import subH5QueueApply from '@/views/submodule/h5/queue/apply'

  export default {
    components: {
      subH5QueueApply
    }
  }
</script>