<template>
  <div class="head-apply-user">
    <div class="apply-user-box">
      <dl class="apply-user">
        <dt>{{ name }}，您好！</dt>
        <dd>请选择职位后进行投递～<br>有问题可以现场老师进行咨询</dd>
      </dl>
      <div class="img-workbench"><img src="@/assets/images/queue/img_workbench.png" /></div>
    </div>
  </div>
</template>

<script>
  import './index.scss'

  export default {
    props: {
      name: {
        type: String,
        default: ''
      }
    }
  }
</script>